<template>
	<div>
		<header class="modal-card-head">
			<h4 v-if="historic" class="modal-card-title ellipsis">
				<span>
					Change log
				</span>
			</h4>
			<h4 v-else class="modal-card-title ellipsis">
				<span>
					{{ title }} <strong>Message</strong>
				</span>
				<span class="is-block is-contest">{{ contest.user.full_name }} • {{ contest.date }} • {{ contest.hour }}</span>
			</h4>
		</header>
		<div class="modal-card-body modal-select">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<ul v-if="historic" class="history">
				<li v-for="historic in contest.historic" :key="historic.id">
					<p class="history-title"><strong>{{ historic.date }} • {{ historic.hour }}</strong></p>
					<p>{{ historic.message }}</p>
				</li>
			</ul>
			<b-input v-else type="textarea" rows="8" v-model="contestLocal.message"></b-input>
		</div>
		<footer class="modal-card-foot">
			<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Close</b-button>
			<b-button v-if="!historic" native-type="button" class="is-rounded is-primary" :loading="loading" @click="update()">Atualizar</b-button>
		</footer>
	</div>
</template>

<script>
import Api from '@/services/api'
import { errorToast } from '@/mixins/toast'

export default {
	props: {
		contest: {
			required: true
		},
		historic: {
			default: false
		},
		edited: {
			required: false
		}
	},
	data() {
		return {
			loading: false,
			isOpening: false,
			contestLocal: {
				message: ''
			}
		}
	},
	mounted() {
		this.contestLocal = { message: '', ...this.contest }
	},
  	computed: {
		title () {
			return this.contest.history ? 'Histórico da' : 'Edit'
		}
	},
	methods: {
        async update() {
			try {
				this.loading = true
				const response = await Api.post(`contest/update/${this.contestLocal.id}`, {
					rating_criteria_id: this.contestLocal.rating_criteria_id,
					message: this.contestLocal.message
				})

				if (response.status === 200) {
					this.edited()
					this.$emit('close')
				}
			} catch (e) {
				console.log(e)
				errorToast('An <strong>error</strong> has occurred while updating the message')
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
